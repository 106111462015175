<template>
  <div>
    <div v-if="validEmail" class="pt-4 login-tabs-container">
      <div class="mb-4">
        <h1 class="mb-2">Utu Business</h1>
        <h3 class="text-muted">Create your account</h3>
      </div>
      <div class="pt-2">
        <vs-input
          name="name"
          icon-no-border
          icon="person"
          label-placeholder="Firstname Lastname"
          v-model="name"
          size="large"
          class="w-100"
        />
        <vs-input
          name="business_name"
          icon-no-border
          icon="account_balance"
          label-placeholder="Business Name"
          v-model="business_name"
          size="large"
          class="w-100 mt-4"
        />
        <vs-input
          name="country"
          icon-no-border
          icon="outlined_flag"
          label-placeholder=""
          v-model="country"
          disabled
          size="large"
          class="w-100 mt-4"
        />
        <vs-input
          name="email"
          icon-no-border
          icon="email"
          label-placeholder="Email"
          v-model="email"
          size="large"
          disabled
          class="w-100 mt-4"
        />
        <!-- <span class="text-danger text-sm">{{ errors.first('email') }}</span> -->

        <vs-input
          type="password"
          name="password"
          icon-no-border
          icon="lock"
          label-placeholder="Password"
          v-model="password"
          size="large"
          class="w-100 mt-4 mb-3"
        />
        <p class="text-center mt-4 mb-4 text-danger" v-if="status === 'error'">
          {{ message }}
        </p>
        <vs-divider>Accept Our Terms</vs-divider>
        <span class="text-justify"
          ><vs-checkbox class="float-left" v-model="accept_terms"></vs-checkbox>
          Clicking this box means you have read and hence agree to the terms and
          conditions in our Utu's
          <a
            class="text-decoration-none"
            href="https://utu.com/terms-or-service"
            target="_blank"
            >Terms of Use</a
          >,
          <a href="https://utu.com/merchant-agreement" target="_blank"
            >Merchant Agreement</a
          >
          and
          <a href="https://utu.com/privacy-policy" target="_blank"
            >Privacy Policy</a
          ></span
        >
        <vs-button
          class="w-100 mt-4"
          size="large"
          :disabled="!validateForm"
          @click="register"
          >Start Registration</vs-button
        >
        <span>Already have an account? </span>
        <vs-button
          class="mt-4 font-weight-bold"
          type="flat"
          @click="routeToLogin"
          >Login now</vs-button
        >
      </div>
    </div>
    <div v-if="!validEmail" class="pt-4 login-tabs-container">
        <div class="mb-4">
            <h1 class="mb-2">Utu Business</h1>
            <h3 class="text-muted">Create your account</h3>
        </div>
        <h5>
            Your email verification process was initiated on a different browser or device. For security reasons, please use the same browser and device to start and complete the process.
        </h5>
        <vs-button
          class="w-100 mt-4"
          size="large"
          @click="restart"
          >Restart email verification</vs-button
        >
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { auth } from "../../../firebase/auth"
/**
 * todo: Implement ccountry selection, where you heard about Utu, and referral code.
 */

export default {
  name: "CompleteRegistration",
  created() {
    window.console.log("Created...", window.location.href);
    window.console.log("Params:", this.$route.params);
    window.console.log("Queries:", this.$route.query);
    this.validEmail = auth.isSignInWithEmailLink(window.location.href) && window.localStorage.getItem('utupayEmail');
    this.email = window.localStorage.getItem("utupayEmail");
  },
  data() {
    return {
      validEmail: false,
      email: "",
      password: "",
      name: "",
      business_name: "",
      country: "Nigeria",
      accept_terms: false,
    };
  },
  computed: {
    validateForm() {
      return (
        this.email !== "" &&
        this.password.length >= 6 &&
        this.name.trim().split(" ").length === 2 &&
        this.business_name !== "" &&
        this.accept_terms
      );
    },
    ...mapState("auth", ["status", "message"]),
  },
  methods: {
    ...mapMutations('auth', ['reset']),
    checkLogin() {
    // If user is already logged in notify
    if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
        });

        return false;
    }
    return true;
    },
    register() {
    // Loading
    this.$vs.loading();

    const payload = {
        userDetails: {
        user_email: this.email,
        password: this.password,
        accept_terms: this.accept_terms,
        user_name: this.name,
        country: this.country,
        business_name: this.business_name,
        creation_date: Date.now()
        },
        notify: this.$vs.notify,
        closeAnimation: this.$vs.loading.close,
        next: () => this.$router.push("/app"),
    };
    this.$store.dispatch("auth/register", payload);
    },
    routeToLogin() {
        this.reset();
    this.$router.push("/login").catch(() => {});
    },
    restart() {
        this.reset();
        this.$router.push('/register');
    }
  },
};
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }

  span {
    line-height: 1.6;
    a {
      color: #f26d5b;
    }
  }
}
</style>